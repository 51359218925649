import { uniq } from 'lodash'
import React, { memo } from 'react'

import { Image, Props as ImageProps } from './Image'

export interface Props {
  className?: string
  images?: ImageProps[]
  title?: string
}

export const Category = memo(function Category({ className, images }: Props) {
  if (!images) {
    return null
  }

  return (
    <>
      {uniq(images).map((item, index) => (
        <Image className={className} key={index} {...item} />
      ))}
    </>
  )
})
