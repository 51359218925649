import styled from '@emotion/styled'
import React, { memo } from 'react'

export interface Props {
  category: string
  active?: boolean
  label: string
  onClick?: (e: any) => void
}

export const Filter = memo(function Filter({
  active = false,
  category,
  label,
  onClick,
}: Props) {
  if (!label) {
    return null
  }
  return (
    <Container
      className={active ? 'active' : undefined}
      data-category={category}
      onClick={onClick}
    >
      {label}
    </Container>
  )
})

const Container = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  cursor: pointer;
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 600;
  font-size: 0.9375rem;
  letter-spacing: 0.05em;
  margin: 0 1.1875rem 1.1875rem;
  position: relative;
  text-transform: uppercase;
  transition: 0.2s ease-out;
  &:after {
    content: '';
    height: 0.125rem;
    background: #53a78b;
    position: absolute;
    right: 100%;
    bottom: -0.1875rem;
    left: 0;
    transition: 0.2s ease-in-out;
  }
  &:hover {
    color: ${({ theme }) => theme.colors.variants.neutralDark4};
  }
  &.active {
    color: #53a78b;
    &:after {
      right: 0;
    }
  }
`
