import 'photoswipe/dist/photoswipe.css'
import 'photoswipe/dist/default-skin/default-skin.css'

import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme'
import { useVocabularyData } from 'app/utils/vocabulary'
import { rgba } from 'emotion-rgba'
import React, { memo, useState } from 'react'
import { Gallery as PhotoSwipe } from 'react-photoswipe-gallery'

import { Category, Props as CategoryProps } from './Category'
import { Filter, Props as FilterProps } from './Filter'

export interface Props {
  filters?: FilterProps[]
  categories?: CategoryProps[]
  languageCode: string
  siteName?: string
  title?: string
}

export const GalleryCategories = memo(function GalleryCategories({
  filters,
  categories,
  languageCode,
  siteName,
  title,
}: Props) {
  if (!categories) {
    return null
  }

  const firstCategory = filters
    ? filters.map((item) => item.category)[0]?.toLocaleLowerCase()
    : null
  const [filteredCategory, setFilteredCategory] = useState('all')

  return (
    <Container>
      {siteName ? <Heading>{siteName}</Heading> : null}
      {title ? <Title>{title}</Title> : null}
      {filters && filters.length > 1 ? (
        <Filters>
          <Wrapper dial={2} row wrap>
            <Filter
              active={filteredCategory === 'all'}
              category="all"
              label={useVocabularyData('all', languageCode)}
              onClick={() => {
                setFilteredCategory('all')
              }}
            />
            {filters.map((item, index) => {
              const category = item.category
                ? item.category.replace(/[^a-zA-Z]/g, '').toLocaleLowerCase()
                : undefined

              return (
                <Filter
                  active={filteredCategory === category}
                  key={index}
                  {...item}
                  onClick={(e) => {
                    setFilteredCategory(e.target.getAttribute('data-category'))
                  }}
                />
              )
            })}
          </Wrapper>
        </Filters>
      ) : null}

      <Categories row wrap>
        <PhotoSwipe
          options={{
            barsSize: {
              top: 150,
              bottom: 100,
            },
            closeElClasses: '',
            closeOnScroll: false,
            closeOnVerticalDrag: false,
            pinchToClose: false,
            fullscreenEl: false,
            zoomEl: false,
            shareEl: false,
            preloaderEl: false,
            mouseUsed: true,
            tapToToggleControls: false,
            history: false,
            showHideOpacity: true,
            maxSpreadZoom: 1,
            getDoubleTapZoom: function (isMouseClick: any, item: any) {
              return item.initialZoomLevel
            },
          }}
        >
          {categories.map((item, index) => {
            const category = item.title
              ? item.title.replace(/[^a-zA-Z]/g, '').toLocaleLowerCase()
              : undefined

            return (
              <Category
                className={
                  filteredCategory === category || filteredCategory === 'all'
                    ? 'visible'
                    : undefined
                }
                key={index}
                {...item}
              />
            )
          })}
        </PhotoSwipe>
      </Categories>
    </Container>
  )
})

const Container = styled.section`
  padding-top: 13.75rem;
  padding-bottom: 7.5rem;
  text-align: center;

  @media (max-width: 1023px) {
    padding-top: 10rem;
    padding-bottom: 3.75rem;
  }

  @media (max-width: 1023px) {
    .pswp__button {
      &.pswp__button--arrow--left,
      &.pswp__button--arrow--right {
        display: none;
      }
    }
  }

  /* OVERRIDE PHOTOSWIPE STYLE */
  .pswp__bg {
    background: ${({ theme }) => theme.colors.variants.neutralLight4};
  }
  .pswp__img--placeholder--blank {
    background: none;
  }
  .pswp--zoom-allowed .pswp__img {
    cursor: default;
  }
  .pswp--touch {
    .pswp__button--arrow--left,
    .pswp__button--arrow--right {
      visibility: visible;
    }
  }
  .pswp__caption {
    display: flex;
    min-height: 0;
    bottom: 46px;
    background: none;
    .pswp__caption__center {
      max-width: none;
      color: ${({ theme }) => theme.colors.variants.neutralDark4};
      font-family: ${({ theme }) => theme.fontFamily.paragraph};
      font-size: 1rem;
      line-height: 1.75rem;
      text-align: center;
    }
  }
  .pswp__top-bar {
    height: auto;
    background: none;
  }
  .pswp__button {
    width: 4.9375rem;
    height: 4.9375rem;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 1;
    &.pswp__button--arrow--left,
    &.pswp__button--arrow--right {
      background-color: #edf4f1;
      background-image: url('/pswp_arrow.svg');
      background-position: center;
      background-repeat: no-repeat;
      margin-top: -1rem;
      transition: 0.3s ease-in-out;
      &:before {
        display: none;
        background-color: transparent;
      }
      &:hover {
        background-color: ${rgba(theme.colors.variants.neutralLight3, 0.8)};
        border-color: transparent;
      }
    }
    &.pswp__button--close {
      width: 46px;
      height: 46px;
      background-image: url('/pswp_close.svg');
      background-size: contain;
      position: absolute;
      top: 13px;
      right: -10px;
      transform: translateX(-50%);
    }
    &.pswp__button--arrow--left {
      left: 0;
    }
    &.pswp__button--arrow--right {
      right: 0;
      transform: scaleX(-1);
    }
  }
  .pswp__counter {
    height: auto;
    color: #53a78b;
    font-family: ${({ theme }) => theme.fontFamily.paragraph};
    font-size: 0.9375rem;
    font-weight: 500;
    line-height: 0.875rem;
    opacity: 1;
    position: fixed;
    top: auto;
    bottom: 30px;
    right: 0;
    text-align: center;
    text-transform: uppercase;
  }

  @media (-webkit-min-device-pixel-ratio: 1.1),
    (-webkit-min-device-pixel-ratio: 1.09375),
    (min-resolution: 1.1dppx),
    (min-resolution: 105dpi) {
    .pswp--svg .pswp__button--close:before,
    .pswp--svg .pswp__button--arrow--left:before,
    .pswp--svg .pswp__button--arrow--right:before {
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAQgAAABYCAQAAACjBqE3AAAB6klEQVR4Ae3bsWpUQRTG8YkkanwCa7GzVotsI/gEgk9h4Vu4ySLYmMYgbJrc3lrwZbJwC0FMt4j7F6Y4oIZrsXtgxvx/1c0ufEX4cnbmLCmSJEmSJEmSJEmSJP3XCBPvbJU+8doWmDFwyZpLBmYlNJebz0KwzykwsuSYJSNwykEJreV2BaBMaLIQZ2xYcFgqDlmw4ayE/FwL0dDk4Qh4W37DAjgqIT+3HRbigjH+iikVdxgZStgyN0Su2sXIeTwTT+esdpcbIlfNAuZ/TxresG4zV8kYWSZNiKUTokMMSWeIwTNEn4fK2TW3gRNgVkJLuVksROA9G+bEvoATNlBCa7nZXEwdxEZxzpKRKFh+bsv8LmPFmhX1OwfIz81jIRJQ5eeqG9B+riRJkiRJkiRJkiRJkiRJkiRJUkvA/8RQoEpKlJWINFkJ62AlrEP/mNBibnv2yz/A3t7Uq3LcpoxP8COjC1T5vxoAD5VdoEqdDrd5QuW1swtUSaueh3zkiuBiqgtA2OlkeMcP/uDqugsJdbjHF65VdPMKwS0+WQc/MgKvrIOHysB9vgPwk8+85hmPbnQdvHZyDMAFD7L3EOpgMcVdvnHFS0/vlatrXvCVx0U9gt3fxvnA0/hB4nmRJEmSJEmSJEmSJGmHfgFLaDPoMu5xWwAAAABJRU5ErkJggg==) !important;
    }
  }
`

const Heading = styled.h1`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1.875rem;
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: 500;
  font-size: 3.0625rem;
  text-align: center;

  @media (max-width: 1023px) {
    font-size: 32px;
  }
`

const Title = styled.div`
  margin-top: 1rem;
  padding: 0 1.875rem;
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 600;
  font-size: 0.9375rem;
  letter-spacing: 0.05em;
  text-align: center;
  text-transform: uppercase;
`

const Filters = styled.div`
  max-width: 77vw;
  margin: 4.5rem auto 2.25rem;
  padding-bottom: 3.2rem;
  position: relative;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    border: 3px solid #edf4f1;
    border-bottom: 0;
    position: absolute;
    top: 0.5rem;
    left: 0;
  }

  @media (max-width: 1023px) {
    max-width: none;
    margin: 3.75rem auto 1.875rem;
    padding-bottom: 0;
    &:before {
      display: none;
    }
  }
`

const Wrapper = styled(FlexBox)`
  width: 40.625rem;
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  margin: auto;

  @media (max-width: 1199px) {
    width: 37.5rem;
  }

  @media (max-width: 1023px) {
    width: 100%;
    max-width: none;
  }
`

const Categories = styled(FlexBox)`
  margin-left: -1.4375rem;
  margin-bottom: -1.4375rem;
`
