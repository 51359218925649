import styled from '@emotion/styled'
import { memo } from 'react'

export const Separator = memo(function Separator() {
  return <Container />
})

const Container = styled.div`
  width: 4px;
  margin: 0 auto 6rem;

  &:after {
    content: '';
    display: block;
    width: 3px;
    height: 120px;
    background: #edf4f1;
    transform: translateX(2px);
  }

  @media (max-width: 1023px) {
    margin-bottom: 3.75rem;

    &:after {
      height: 90px;
    }
  }
`
